'use client';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/store';
import { toggleRTL, toggleTheme, toggleMenu, toggleLayout, toggleAnimation, toggleNavbar, toggleSemidark } from '@/store/themeConfigSlice';
import Loading from '@/components/layouts/loading';
import { getTranslation } from '@/i18n';
import { registerLicense } from '@syncfusion/ej2-base';

function App({ children }: PropsWithChildren) {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            const { initLocale } = await getTranslation();
            dispatch(toggleTheme(localStorage.getItem('theme') || themeConfig.theme));
            dispatch(toggleMenu(localStorage.getItem('menu') || themeConfig.menu));
            dispatch(toggleLayout(localStorage.getItem('layout') || themeConfig.layout));
            dispatch(toggleRTL(localStorage.getItem('rtlClass') || themeConfig.rtlClass));
            dispatch(toggleAnimation(localStorage.getItem('animation') || themeConfig.animation));
            dispatch(toggleNavbar(localStorage.getItem('navbar') || themeConfig.navbar));
            dispatch(toggleSemidark(localStorage.getItem('semidark') || themeConfig.semidark));
            // locale
            initLocale(themeConfig.locale);

            setIsLoading(false);
        };

        initialize();
    }, [dispatch, themeConfig.theme, themeConfig.menu, themeConfig.layout, themeConfig.rtlClass, themeConfig.animation, themeConfig.navbar, themeConfig.locale, themeConfig.semidark]);

    useEffect(() => {
        // Registering Syncfusion license key.
        const secret = process.env.NEXT_PUBLIC_SYNC_KEY || 'dummy';
        registerLicense(secret);

        const link = document.createElement('link');
        link.href = `https://cdn.syncfusion.com/ej2/26.1.35/bootstrap5${themeConfig.isDarkMode ? '-dark' : ''}.css`;
        // link.href = `/syncfusion/bootstrap5${themeConfig.isDarkMode ? '-dark' : ''}.css`;
        link.rel = 'stylesheet';
        link.id = 'theme';
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className={`${(themeConfig.sidebar && 'toggle-sidebar') || ''} ${themeConfig.menu} ${themeConfig.layout} ${
                    themeConfig.rtlClass
                } main-section relative font-nunito text-sm font-normal antialiased`}
            >
                {isLoading ? <Loading /> : children}
            </div>
        </>
    );
}

export default App;
